import { createContext, useState } from 'react';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [fetchedTraders, setFetchedTraders] = useState();

    return <DataContext.Provider
        value={{ fetchedTraders, setFetchedTraders }}
    >
        {children}
    </DataContext.Provider>;
};
