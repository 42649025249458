import { getYear } from 'date-fns';
import moment from 'moment';
import { groupBy } from 'ramda';

const getTermInMonths = (term) => {
    const [duration, unit] = term.split(' ');
    return unit.toLowerCase() === 'm' ? duration : 12 * duration;
};

const getTermInString = (term) => {
    const [duration, unit] = term.split(' ');
    const units = {
        m: 'Month', y: 'Year', w: 'Week', d: 'Day'
    };
    return `${duration} ${units[unit.toLowerCase()]}(s)`;
};

const calculateTenure = (s, e) => {
    const label = e.label.replace(/ /g, '').toLowerCase();
    const matches = label.match(/^(\d+)([dwmy])$/);
    if (matches) {
        let value = parseInt(matches[1], 10);
        const unit = matches[2];
        if (unit === 'm') value /= 12;
        if (unit === 'w') value /= 52;
        if (unit === 'd') value /= 365;
        return value;
    }
    return moment(e.value).diff(s.value, 'days') / 365;
};

const formatPrice = ({ isIrs = false, value }) => {
    if (!isIrs) return value;
    const fractionDigits = Number(value).toString().split('.')[1];
    return Number(value).toFixed(Math.max(2, Math.min(4, fractionDigits?.length) || 0));
};

const getSubAssetType = (type = '', data = {}) => {
    switch (type) {
        case 'cds':
            return 'Cds';
        case 'bond':
            return data?.bondLevel;
        case 'ndf':
            return data?.end1 ? 'Liquidity Swaps' : 'Outright';
        case 'irs':
            if (data.end && !data.end1 && !data.end2) return 'Outright';
            if (data.end && data.end1 && !data.end2) return 'Spread';
            if (data.end && data.end1 && data.end2) return 'Butterfly';
        default:
            return '';
    }
};

const assestTypeMapping = {
    CURRENCY: 'NDF',
    IRS: 'IRS',
    BOND: 'BOND',
    CDS: 'CDS'
};

const byYear = groupBy((item) => getYear(new Date(item.value)));

export {
    calculateTenure, getTermInMonths, getTermInString, formatPrice, getSubAssetType, assestTypeMapping, byYear
};
