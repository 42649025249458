import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';
import { getAuth } from 'services/auth.service';

const bugsnagClient = Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUG_SNAG_API_KEY,
    plugins: [new BugsnagPluginReact(React)],
    releaseStage: process.env.NEXT_PUBLIC_ENVIRONMENT || 'development',
    onError: (event) => {
        const auth = getAuth();
        if (!auth) return;
        const customData = {
            userId: auth?.id,
            name: auth?.name,
            email: auth?.email,
            role: auth?.role
        };
        event.addMetadata('customData', customData);
    }
});
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
const notifyBugSnag = (ErrTitle = '', customData = {}) => {
    bugsnagClient.notify(new Error(ErrTitle), (event) => {
        if (Object.keys(customData).length) {
            event.addMetadata('customData', customData);
        }
    });
};
export { bugsnagClient, ErrorBoundary, notifyBugSnag };
