import {
    createContext,
    useState
} from 'react';

export const StatusContext = createContext();

export const StatusProvider = ({ children }) => {
    const [callStatus, setCallStatus] = useState(null);
    const [inputDeviceId, setInputDeviceId] = useState('');
    const [userStatus, setUserStatus] = useState('');
    const [globalCommand, setGlobalCommand] = useState(null);
    const [brokers, setBrokers] = useState([]);
    const [isDeletedPrices, setIsDeletedPrices] = useState(false);

    return (
        <StatusContext.Provider value={{
            callStatus,
            setCallStatus,
            inputDeviceId,
            setInputDeviceId,
            userStatus,
            setUserStatus,
            globalCommand,
            setGlobalCommand,
            brokers,
            setBrokers,
            isDeletedPrices,
            setIsDeletedPrices

        }}>
            {children}
        </StatusContext.Provider>
    );
};
