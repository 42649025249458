import ApprovalPopup from 'organisms/ApprovalPopup';
import ApprovalWaiting from 'organisms/ApprovalWaiting';
import { useEffect, useRef, useState } from 'react';
import { socket, socketEmit } from 'services/socket';

export default function TradeRequest() {
    const [showApprovalWaiting, setShowApprovalWaiting] = useState(false);
    const [awaitingTradeRequests, setAwaitingTradeRequests] = useState([]);
    const [showApprovalPopup, setShowApprovalPopup] = useState(false);
    const [tradeRequestAction, setTradeRequestAction] = useState(null);
    const [receivedTradeRequests, setReceivedTradeRequests] = useState([]);
    const [currentRequest, setCurrentRequest] = useState(null);
    const [isInsufficientSize, setIsInsufficientSize] = useState(false);
    const receivedTradeRequestsRef = useRef(receivedTradeRequests);
    const showApprovalPopupRef = useRef(showApprovalPopup);
    const currentRequestRef = useRef(null);

    const onTradeRequestCreation = () => {
        socketEmit('/get/trade-requests');
    };

    const formatTradeRequestDetails = (data) => data?.map((item) => {
        if (item.BondPrice.IrsBond) {
            return ({
                bondName: `${item.BondPrice.IrsBond.irs.name}${item.BondPrice.IrsBond.end2
                    ? ' - Butterfly Trade' : item.BondPrice.IrsBond.end1 ? ' - Spread Trade' : ''}`,
                details: [
                    { label: 'Start Date', value: item.BondPrice.IrsBond.startLabel },
                    {
                        label: 'Tenor',
                        value: item.BondPrice.IrsBond.end1
                            ? `${`${item.BondPrice.IrsBond.end1Label.toLowerCase().replace(/ /g, '')} x `}${item.BondPrice.IrsBond.end2Label
                                ? `${item.BondPrice.IrsBond.end2Label
                                    .toLowerCase().replace(/ /g, '')} x ` : ''}${item.BondPrice.IrsBond.endLabel.toLowerCase().replace(/ /g, '')}`
                            : item.BondPrice.IrsBond.endLabel
                    },
                    {
                        label: `${item.BondPrice.IrsBond.end1 ? 'Trade Level' : 'Rate'}`,
                        value: item.BondPrice.price,
                        ...(item.BondPrice.IrsBond.end1 && { unit: 'bps' })
                    },
                    { label: 'DVO1', value: item.size, unit: 'k' },
                    { label: 'Notional', value: item.notional, unit: `${item.BondPrice.IrsBond.irs.currency}(M)` }
                ],
                expiry: item.requestExpiry,
                tradeRequestId: item.id,
                size: `${Number.isNaN(Number(item.size)) ? '' : Number(item.size).toLocaleString('en-US')}k DVO1`,
                tradeSize: item.size,
                requestType: item.BondPrice.type,
                requestorId: item.requestorId,
                bondType: 'Irs',
                bondPriceId: item.bondPriceId,
                isTraderPresent: !!item.BondPrice.traderId
            });
        }
        return {
            bondName: `${item.BondPrice.CurrencyBond.currency.name}${item.BondPrice.CurrencyBond.end1 ? ' - Liquidity Swaps' : ''}`,
            details: [
                { label: 'Start Date', value: item.BondPrice.CurrencyBond.startLabel },
                {
                    label: 'Tenor',
                    value: item.BondPrice.CurrencyBond.end1
                        ? `${`${item.BondPrice.CurrencyBond.end1Label.toLowerCase().replace(/ /g, '')} x `}${item.BondPrice.CurrencyBond.end2Label
                            ? `${item.BondPrice.CurrencyBond.end2Label.toLowerCase()
                                .replace(/ /g, '')} x ` : ''}${item.BondPrice.CurrencyBond.endLabel.toLowerCase().replace(/ /g, '')}`
                        : item.BondPrice.CurrencyBond.endLabel
                },
                {
                    label: 'Exchange rate',
                    value: item.BondPrice.price,
                    unit: item.BondPrice.CurrencyBond.end1 ? 'swp pts' : item.BondPrice.CurrencyBond.currency.currency
                },
                { label: 'Notional', value: item.size, unit: 'USD(M)' }
            ],
            size: `${Number.isNaN(Number(item.size)) ? '' : Number(item.size).toLocaleString('en-US')}M Notional`,
            expiry: item.requestExpiry,
            tradeRequestId: item.id,
            tradeSize: item.size,
            requestType: item.BondPrice.type,
            requestorId: item.requestorId,
            bondType: 'Currency',
            bondPriceId: item.bondPriceId,
            isTraderPresent: !!item.BondPrice.traderId
        };
    });

    const onTradeRequestsFetch = ({ data }) => {
        const requestDetails = formatTradeRequestDetails(data);
        setAwaitingTradeRequests(requestDetails);
        setShowApprovalWaiting(true);
    };

    const showNextRequest = (bondPriceId = null) => {
        setReceivedTradeRequests((prevRequests) => {
            const filteredRequests = prevRequests?.filter(
                (request) => request?.bondPriceId !== bondPriceId
            );

            if (filteredRequests.length > 0) {
                const [nextRequest, ...remainingRequests] = filteredRequests;
                setCurrentRequest(nextRequest);
                setTradeRequestAction(nextRequest.action || null);
                nextRequest.action === 'InsufficientSize' ? setIsInsufficientSize(true) : setIsInsufficientSize(false);
                setShowApprovalPopup(true);
                return remainingRequests;
            }

            return filteredRequests;
        });
    };

    const onTradeRequestReceived = (data) => {
        const isPopupVisible = showApprovalPopupRef.current;
        setReceivedTradeRequests((prevRequests) => [...prevRequests, { ...data.details, action: null }]);
        if (!isPopupVisible) {
            showNextRequest(null);
        }
    };

    const onOwnerTradeRequestsFetch = ({ data }) => {
        if (data?.length) {
            const requestDetails = formatTradeRequestDetails(data);
            setReceivedTradeRequests(requestDetails);
            showNextRequest(null);
        }
    };

    const resetTimer = (expiry) => {
        const expiryTime = new Date(expiry);
        const currentTime = new Date();
        return Math.max(Math.floor((expiryTime - currentTime) / 1000), 0);
    };

    const closeApprovalWaitingPopup = () => {
        setShowApprovalWaiting(false);
    };

    const closeApprovalPopup = (bondPriceId = null) => {
        setShowApprovalPopup(false);
        showNextRequest(bondPriceId);
    };

    const onTradeRequestAction = (data) => {
        if (data.action === 'CloseTradeRequest') {
            setTradeRequestAction(null);

            const tradeRequestId = data?.tradeRequestId;
            const bondPriceId = data?.closePendingRequestsOfBondPrice ? data?.bondPriceId : null;

            if (currentRequestRef.current?.tradeRequestId === tradeRequestId) {
                closeApprovalPopup(bondPriceId);
            } else {
                setReceivedTradeRequests((prevRequests) => prevRequests.filter((request) => request.tradeRequestId !== tradeRequestId));
            }
        } else {
            const isPopupVisible = showApprovalPopupRef.current;
            setReceivedTradeRequests((prevRequests) => [...prevRequests, { ...data.details, action: data.action }]);
            if (!isPopupVisible) {
                showNextRequest(null);
            }
            setAwaitingTradeRequests((prevRequests) => {
                const updatedRequests = prevRequests.filter(
                    (request) => request.tradeRequestId !== data.details.tradeRequestId
                );
                if (updatedRequests.length === 0) {
                    closeApprovalWaitingPopup();
                }
                return updatedRequests;
            });
        }
    };

    useEffect(() => {
        receivedTradeRequestsRef.current = receivedTradeRequests;
    }, [receivedTradeRequests]);

    useEffect(() => {
        showApprovalPopupRef.current = showApprovalPopup;
    }, [showApprovalPopup]);

    useEffect(() => {
        currentRequestRef.current = currentRequest;
    }, [currentRequest]);

    useEffect(() => {
        socket.on('/post/trade-requests', onTradeRequestCreation);
        socket.on('/get/trade-requests', onTradeRequestsFetch);
        socket.on('/trade-request-action', onTradeRequestAction);
        socket.on('/trade-request', onTradeRequestReceived);
        socket.on('/get/trade-requests-by-owner', onOwnerTradeRequestsFetch);

        socketEmit('/get/trade-requests');
        socketEmit('/get/trade-requests-by-owner');

        return () => {
            socket.off('/post/trade-requests', onTradeRequestCreation);
            socket.off('/get/trade-requests', onTradeRequestsFetch);
            socket.off('/trade-request-action', onTradeRequestAction);
            socket.off('/trade-request', onTradeRequestReceived);
            socket.on('/get/trade-requests-by-owner', onOwnerTradeRequestsFetch);
        };
    }, []);

    return <>
        {showApprovalWaiting && <ApprovalWaiting
            infoTable={awaitingTradeRequests}
            setAwaitingTradeRequests={setAwaitingTradeRequests}
            closePopup={closeApprovalWaitingPopup}
        />}

        {showApprovalPopup && currentRequest && (
            <ApprovalPopup
                infoTable={currentRequest}
                closePopup={closeApprovalPopup}
                tradeRequestAction={tradeRequestAction}
                resetTimer={() => resetTimer(currentRequest.expiry)}
                isInsufficientSize={isInsufficientSize}
            />
        )}
    </>;
}
