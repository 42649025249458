const ApprovalGrid = ({ data, tradeRequestAction }) => {
    const getFormattedValue = (label, value) => ((Number.isNaN(Number(value)) || label !== 'Notional')
        ? value : (Number(value).toFixed(1)).toLocaleString('en-US'));

    return (
        <div className="bg-gray-600 text-gray-100 rounded w-full border border-gray-400 my-3">
            <table className="border-collapse text-xs font-medium">
                <tbody>
                    {data?.map((row, index) => (
                        <tr key={index} className="border-b last:border-b-0 border-gray-400">
                            <td className="py-1 px-2 w-[120px] bg-gray-500 border-r border-gray-400">{row.label}</td>
                            <td className="py-1 px-2">
                                {tradeRequestAction && row.requestedValue ? getFormattedValue(row.label, row.requestedValue) : getFormattedValue(row.label, row.value)
                                } <span
                                    className='text-warning-200'>{row.unit}</span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ApprovalGrid;
